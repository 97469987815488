/** @jsx jsx */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  jsx,
  Box,
  Heading,
  Flex,
  Button,
  Input,
  Textarea,
  Grid,
  Select,
  Close,
} from 'theme-ui'
import firebase from 'gatsby-plugin-firebase'
import { AuthContext } from 'context/auth-context'
import { Line } from 'react-chartjs-2'
import { generateDatesFirebase, getSales, updateMonthlyExpenses } from 'api'
import { getTimeFirebaseDate } from 'helpers'
import { MONTHS } from 'consts'
import Plus from 'assets/plus.svg'
import { EXPENSES } from 'types'

export const Expenses: React.FC = () => {
  const [expenses, setExpenses] = useState<any>()
  const [lastDoc, setLastDoc] = useState<any>()
  const [nextVisible, setNextVisibility] = useState<any>()
  const [addVisiblity, setAddVisiblity] = useState<boolean>(false)
  const [updateVisibility, setUpdateVisibility] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>()
  const [deleteConfirm, showDeleteConfirm] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [isHoverId, setIsHover] = useState<string>('')

  const onMouseOver = useCallback((id: string) => {
    setIsHover(id)
  }, [])
  const onMouseOut = useCallback(() => {
    setIsHover('')
  }, [])

  const fetchData = useCallback(async () => {
    const db = firebase.firestore()
    const data = await db
      .collection('Expenses')
      .orderBy('date', 'desc')
      .limit(10)
      .get()
      .then(async function (collections) {
        const lastDoc = collections.docs[collections.docs.length - 1]
        setLastDoc(lastDoc)
        setNextVisibility(!!lastDoc)

        const expenses = collections.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }))

        if (expenses.length) {
          setExpenses(expenses)
        }
      })
  }, [])

  const onAdd = useCallback(
    async (event: any) => {
      event.preventDefault()
      event.stopPropagation()
      const form = event.target.elements
      let now = new Date()
      const date = firebase.firestore.Timestamp.fromDate(now)
      const expense = {
        date,
        value: Number(form.value.value),
        description: form.description.value,
        type: form.type.value,
        id: '',
      }
      const db = firebase.firestore()
      await db
        .collection('Expenses')
        .add(expense)
        .then(docRef => {
          console.warn('Document written with ID: ', docRef.id)
          expense.id = docRef.id
          if (!!expense) {
            const newExpenses = [...expenses]
            newExpenses.unshift(expense)
            setExpenses(newExpenses)
          } else {
            setExpenses([expenses])
          }
          setAddVisiblity(false)
        })
      updateMonthlyExpenses(expense)
    },
    [expenses]
  )

  const onUpdate = useCallback(
    async (event: any, id: string) => {
      event.preventDefault()
      event.stopPropagation()
      const form = event.target.elements
      const item = expenses.find((expense: any) => expense.id === id)
      const index = expenses.findIndex((expense: any) => expense.id === id)

      const expense = {
        value: Number(form.value.value),
        description: form.description.value,
        type: form.type.value,
        date: item.date,
        id,
      }
      if (item.value !== expense.value) {
        const newExpense = expense.value
        const oldExpense = item.value

        updateMonthlyExpenses({
          value: newExpense - oldExpense,
          date: item.date,
        })
      }
      const newExpenses = [...expenses]
      newExpenses[index] = expense

      setUpdateVisibility(false)
      setExpenses(newExpenses)
      const db = firebase.firestore()
      await db
        .collection('Expenses')
        .doc(id)
        .update(expense)
        .then(function () {
          console.warn('Document successfully updated!')
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error('Error updating document: ', error)
        })
    },
    [expenses]
  )

  const onDelete = useCallback(async () => {
    if (expenses) {
      const index: number = expenses.findIndex(
        (expense: any) => expense.id === deleteId
      )
      if (index > -1) {
        setExpenses(expenses.filter((item: any) => item.id !== deleteId))
        showDeleteConfirm(false)
        const db = firebase.firestore()
        await db
          .collection('Expenses')
          .doc(deleteId)
          .delete()
          .then(function () {
            updateMonthlyExpenses(expenses[index], true)
            console.warn('Document successfully deleted!')
          })
          .catch(function (error) {
            console.error('Error removing document: ', error)
          })
        setDeleteId('')
      }
    }
  }, [expenses, deleteId])

  const showEdit = useCallback(
    (id: string) => {
      if (expenses) {
        const item = expenses.find((expense: any) => expense.id === id)
        setActiveItem(item)
        setUpdateVisibility(true)
      }
    },
    [expenses]
  )

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box>
      <Heading p={2}>Expenses</Heading>
      {expenses &&
        expenses.map((expense: any, key: any) => {
          return (
            <Expense
              expense={expense}
              key={key}
              showEdit={showEdit}
              setDeleteId={setDeleteId}
              showDeleteConfirm={showDeleteConfirm}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              isHoverId={isHoverId}
            />
          )
        })}
      {addVisiblity && (
        <Form setVisibility={setAddVisiblity} onSubmit={onAdd} />
      )}
      {updateVisibility && (
        <Form
          setVisibility={setUpdateVisibility}
          onSubmit={onUpdate}
          expense={activeItem}
        />
      )}

      {deleteConfirm && (
        <DeleteConfirm
          showDeleteConfirm={showDeleteConfirm}
          onDelete={onDelete}
        />
      )}
      <Box
        sx={{
          textAlign: 'right',
          position: 'fixed',
          bottom: '50px',
          right: '10px',
          fontSize: '40px',
          zIndex: 1,
        }}
        pt={0}
      >
        <Button
          onClick={() => setAddVisiblity(true)}
          px={0}
          py={0}
          sx={{
            svg: {
              width: '80%',
              height: '80%',
            },
            borderRadius: '100%',
            height: '50px',
            width: '50px',
            lineHeight: 0.9,
            padding: '12px',
          }}
          bg="secondary"
        >
          <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Plus />
          </Flex>
        </Button>
      </Box>
    </Box>
  )
}

const Expense = (props: any) => {
  const {
    expense,
    setDeleteId,
    showDeleteConfirm,
    onMouseOver,
    onMouseOut,
    isHoverId,
  } = props

  const date = getTimeFirebaseDate(expense.date)

  return (
    <Box
      bg="secondaryDark"
      sx={{ position: 'relative' }}
      onMouseOver={() => onMouseOver(expense.id)}
      mt={1}
    >
      <Grid
        gap={0}
        p={2}
        columns={3}
        sx={{
          position: 'relative',
        }}
      >
        <Box>{expense.type}</Box>
        <Box sx={{ textAlign: 'right' }}>{date.toDateString()}</Box>
        <Box sx={{ textAlign: 'right' }}>{expense.value}</Box>
      </Grid>
      {isHoverId === expense.id && (
        <Flex
          onMouseOut={onMouseOut}
          p={1}
          sx={{
            position: 'absolute',
            justifyContent: 'space-around',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.8)',
            top: 0,
          }}
        >
          <Button
            py={0}
            bg="bgRush"
            onClick={() => {
              showDeleteConfirm(true)
              setDeleteId(expense.id)
            }}
          >
            Delete
          </Button>
          <Button py={0} onClick={() => props.showEdit(expense.id)}>
            Edit
          </Button>
        </Flex>
      )}
    </Box>
  )
}

const DeleteConfirm = (props: any) => {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: 9999999,
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        top: 0,
      }}
      bg="background"
    >
      r u sure u wanna delete
      {/* {orders?.find(el => el.id === deleteId)?.brand}? */}
      <Box>
        <Button py={1} px={3} mx={1} bg="bgRush" onClick={props.onDelete}>
          Yep
        </Button>
        <Button
          py={1}
          px={3}
          mx={1}
          bg="secondary"
          onClick={() => props.showDeleteConfirm(false)}
        >
          Nah
        </Button>
      </Box>
    </Flex>
  )
}

const Form = (props: any) => {
  const { expense } = props || {}
  const { type, value, description, id } = expense || {}

  const onSubmit = (event: any) => {
    props.onSubmit(event, id)
  }

  return (
    <Flex
      sx={{
        position: 'fixed',
        top: 0,
        height: '100%',
        width: '100%',
        alignItems: 'center',
        zIndex: 99,
        justifyContent: 'center',
      }}
      bg="background"
      p={2}
    >
      <Box
        sx={{
          position: 'fixed',
          top: '5px',
          right: '5px',
          background: '#231F1F',
          zIndex: '1',
          borderRadius: '5px',
          border: '1px solid #f1f1f1',
        }}
      >
        <Close onClick={() => props.setVisibility(false)} />
      </Box>
      <Box as="form" onSubmit={onSubmit}>
        <Grid columns={2} gap={2}>
          <Select
            name="type"
            sx={{
              option: {
                color: '#231F1F',
              },
            }}
            defaultValue={type}
          >
            {Object.keys(EXPENSES).map(key => (
              <option value={key}>{key}</option>
            ))}
          </Select>
          <Input
            name="value"
            placeholder="value"
            defaultValue={value || 0}
            type="number"
            sx={{ textAlign: 'right' }}
          />
        </Grid>
        <Textarea
          mt={2}
          name="description"
          placeholder="description"
          defaultValue={description || ''}
        />
        <Button mt={2} sx={{ width: '100%' }}>
          Save
        </Button>
      </Box>
    </Flex>
  )
}

export default Expenses
