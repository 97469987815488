/** @jsx jsx */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { jsx, Box, Heading, Flex } from 'theme-ui'

import { AuthContext } from 'context/auth-context'
import { Line, Bar } from 'react-chartjs-2'
import {
  generateDatesFirebase,
  getMonthlyExpenses,
  getMonthlySales,
  getSales,
} from 'api'
import { getTimeFirebaseDate } from 'helpers'
import { MONTHS } from 'consts'

const data = {
  labels: [],
  datasets: [
    {
      label: 'Sales',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: '#FDC300',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [],
    },
  ],
}

const dataExpenses = {
  labels: [],
  datasets: [
    {
      label: 'Monthly Expenses',
      data: [],
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
    },
  ],
}

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

const dataSales = {
  labels: [],
  datasets: [
    {
      label: 'Monthly Sales',
      data: [],
      backgroundColor: 'rgba(253, 195, 0, 0.2)',
      borderColor: 'rgba(253, 195, 0, 1)',
      borderWidth: 1,
    },
  ],
}

export const Sales: React.FC = () => {
  const [chartData, setChartData] = useState(data)
  const [chartDataExpenses, setChartDataExpenses] = useState(dataExpenses)
  const [chartDataSales, setChartDataSales] = useState(dataSales)
  const [redraw, setRedraw] = useState(true)
  const fetchSales = async () => {
    const sales = await getSales()

    const constData: any = data
    const labels: any = []
    const datasetsData: any = []
    sales.forEach((sale: any) => {
      const date = getTimeFirebaseDate(sale.date)
      labels.push(`${date.getMonth() + 1}/${date.getDate()}`)
      datasetsData.push(sale.sales)
    })
    constData.labels = labels
    constData.datasets[0].data = datasetsData
    setChartData(constData)
    setRedraw(false)
  }

  const fetchMonthlyExpenses = useCallback(async () => {
    const expenses = await getMonthlyExpenses()
    const labels: any = []
    const datasetsData: any = []
    expenses.forEach((expense: any) => {
      const date = getTimeFirebaseDate(expense.date)
      labels.push(
        `${MONTHS[date.getMonth()].substring(0, 3)} ${date.getFullYear()}`
      )
      datasetsData.push(expense.value)
    })
    const constData: any = dataExpenses
    constData.labels = labels
    constData.datasets[0].data = datasetsData
    setChartDataExpenses(constData)
  }, [])

  const fetchMonthlySales = useCallback(async () => {
    const sales = await getMonthlySales()
    const labels: any = []
    const datasetsData: any = []
    sales.forEach((sale: any) => {
      const date = getTimeFirebaseDate(sale.date)
      labels.push(
        `${MONTHS[date.getMonth()].substring(0, 3)} ${date.getFullYear()}`
      )
      datasetsData.push(sale.value)
    })
    const constData: any = dataSales
    constData.labels = labels
    constData.datasets[0].data = datasetsData

    setChartDataSales(constData)
  }, [])

  const fetchData = useCallback(async () => {
    await fetchMonthlyExpenses()
    await fetchMonthlySales()
    await fetchSales()
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-evenly',
          flexDirection: ['column', 'column', 'row'],
        }}
      >
        <Box sx={{ width: ['100%', '100%', '50%'] }}>
          <Heading p={2}>Sales from last 10 Days</Heading>
          <Line data={chartData} redraw={redraw} />
        </Box>
        <Box sx={{ width: ['100%', '100%', '50%'] }}>
          <Heading p={2}>Monthly Sales</Heading>
          <Bar data={chartDataSales} options={options} redraw={redraw} />
        </Box>
        <Box sx={{ width: ['100%', '100%', '50%'] }}>
          <Heading p={2}>Monthly Expenses</Heading>
          <Bar data={chartDataExpenses} options={options} redraw={redraw} />
        </Box>
      </Flex>
    </Box>
  )
}

export default Sales
