export const PRINT_TYPE = {
  GLOSSY: {
    id: 'GLOSSY',
    name: 'Glossy',
  },
  CLEAR: {
    id: 'CLEAR',
    name: 'Clear',
  },
  TARPAULIN: {
    id: 'TARPAULIN',
    name: 'Tarpaulin',
  },
}

export const POC = {
  NINO: {
    name: 'Joe',
  },
  BJORN: {
    name: 'Bjorn',
  },
  RON: {
    name: 'Ron',
  },
}

export const STATUS = {
  RECEIVED: {
    id: 'RECEIVED',
    text: 'Received',
  },
  PRINTING: {
    id: 'PRINTING',
    text: 'Printing',
  },

  PICKUP: {
    id: 'PICKUP',
    text: 'Pick up',
  },
  DONE: {
    id: 'DONE',
    text: 'Done',
  },
}

export const TABS = {
  HOME: 'home',
  LIST: 'list',
  SETTING: 'setting',
  CALCULATOR: 'calculator',
  SALES: 'sales',
  EXPENSES: 'expenses',
}

export const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
