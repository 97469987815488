/** @jsx jsx */
import React, { useCallback, useContext } from 'react'
import { jsx, Button, Flex, Input, Label, Box } from 'theme-ui'
import { AuthContext } from 'context/auth-context'
import Logo from 'assets/logo.svg'
export const Login: React.FC = () => {
  const authContext = useContext(AuthContext)

  const onSubmit = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
    authContext.login(event)
  }, [])

  return (
    <Flex
      sx={{
        backgroundImage: 'url(/grain.png)',
        textRendering: 'optimizeLegibility',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      p={4}
    >
      <Box
        as="form"
        sx={{
          width: '100%',
          maxWidth: '400px',
        }}
        onSubmit={onSubmit}
      >
        <Logo />
        <Label htmlFor="email">Email</Label>
        <Input name="email" mb={3} />
        <Label htmlFor="password">Password</Label>
        <Input type="password" name="password" mb={3} />

        <Button sx={{ width: '100%' }}>Submit</Button>
      </Box>
    </Flex>
  )
}

export default Login
