/** @jsx jsx */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { jsx, Button, Image, Flex, Text, NavLink, Box } from 'theme-ui'
import { AuthContext } from 'context/auth-context'
import Login from 'components/admin/login'

export const Tado: React.FC = (props: any) => {
  const authContext = useContext(AuthContext)

  return <Box>{authContext.isLogin ? props.children : <Login />}</Box>
}

export default Tado
