/** @jsx jsx */
import React, { useCallback, useEffect, useState } from 'react'
import { jsx, Link, Image, Flex, Button, Grid, Box, Heading } from 'theme-ui'
import firebase from 'gatsby-plugin-firebase'
import { IOrder } from 'types'
import { POC, PRINT_TYPE, STATUS } from '../../consts'
import Form from 'components/admin/form'
import { updateDailySales } from 'api'

export default function List(props: any) {
  const [orders, setOrders] = useState<IOrder[] | undefined>()
  const [updateVisibility, setUpdateVisibility] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<IOrder>()
  const [showDeleteConfirm, setDeleteConfirm] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [lastDoc, setLastDoc] = useState<any>()
  const [nextVisible, setNextVisibility] = useState<any>()
  const [isHoverId, setIsHover] = useState<string>('')

  const onMouseOver = useCallback((id: string) => {
    setIsHover(id)
  }, [])
  const onMouseOut = useCallback(() => {
    setIsHover('')
  }, [])

  const showEdit = useCallback(
    (id: string) => {
      if (orders) {
        const item = orders.find(order => order.id === id)
        setActiveItem(item)
        setUpdateVisibility(true)
      }
    },
    [orders]
  )

  const onUpdate = useCallback(
    async (event, id: string) => {
      event.preventDefault()
      event.stopPropagation()
      const form = event.target.elements

      let due = new Date(form.dueDate.value)
      const dueDate = firebase.firestore.Timestamp.fromDate(due)

      const order = {
        brand: form.brand.value,
        clientName: form.clientName.value,
        type: form.type.value,
        poc: form.poc.value,
        status: form.status.value,
        price: Number(form.price.value) || 0,
        totalA4: Number(form.totalA4.value),
        dueDate,
        comment: form.comment.value,
        layoutLink: form.layoutLink.value,
        rush: form.rush.checked,
        quantity: Number(form.quantity.value),
        width: Number(form.width.value),
        height: Number(form.height.value),
        unit: form.unit.value
      } as IOrder

      if (orders) {
        const index: number = orders.findIndex(order => order.id === id)
        order.id = id
        order.orderDate = orders[index].orderDate

        if (index > -1) {
          if (orders[index].price !== order.price) {
            const newPrice = order.price
            const oldPrice = orders[index].price
            let price = 0
            price = newPrice - oldPrice
            updateDailySales(price, orders[index].orderDate)
          }
          const newOrders = [...orders]
          newOrders[index] = order

          setOrders(newOrders)
          const db = firebase.firestore()
          await db
            .collection('Orders')
            .doc(id)
            .update(order)
            .then(function () {
              console.warn('Document successfully updated!')
            })
            .catch(function (error) {
              // The document probably doesn't exist.
              console.error('Error updating document: ', error)
            })
        }
      }
    },
    [orders, setOrders]
  )

  const onDelete = useCallback(async () => {
    if (orders) {
      const index: number = orders.findIndex(order => order.id === deleteId)
      if (index > -1) {
        const newOrders = [...orders]
        newOrders[index].status = STATUS.DONE.id
        setOrders(orders.filter(item => item.id !== deleteId))
        setDeleteConfirm(false)
        const db = firebase.firestore()
        await db
          .collection('Orders')
          .doc(deleteId)
          .delete()
          .then(function () {
            updateDailySales(orders[index].price, orders[index].orderDate, true)
            console.warn('Document successfully deleted!')
          })
          .catch(function (error) {
            console.error('Error removing document: ', error)
          })
        setDeleteId('')
      }
    }
  }, [orders, deleteId])

  const fetchData = useCallback(async () => {
    const db = firebase.firestore()
    const data = await db
      .collection('Orders')
      .orderBy('orderDate', 'desc')
      .limit(10)
      .get()
      .then(async function (collections) {
        // Get the last visible document
        const lastDoc = collections.docs[collections.docs.length - 1]
        setLastDoc(lastDoc)
        setNextVisibility(!!lastDoc)
        // Construct a new query starting at this document,
        // get the next 25 cities.
        const test = collections.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })) as IOrder[]
        if (test) {
          setOrders(test)
        }
      })
  }, [])

  const fetchNext = useCallback(async () => {
    if (!lastDoc) return
    const db = firebase.firestore()
    await db
      .collection('Orders')
      .orderBy('orderDate', 'desc')
      .startAfter(lastDoc)
      .limit(10)
      .get()
      .then(collections => {
        const lastDoc = collections.docs[collections.docs.length - 1]
        const nextData = collections.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })) as IOrder[]
        setOrders(listOrders => {
          if (listOrders) {
            return [...listOrders, ...nextData]
          }
        })
        setLastDoc(lastDoc)
        setNextVisibility(!!lastDoc)
      })
  }, [lastDoc])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box
      sx={{
        backgroundImage: 'url(/grain.png)',
        textRendering: 'optimizeLegibility',
      }}
    >
      <Heading p={2}>Sales</Heading>
      {updateVisibility && (
        <Form
          onSubmit={onUpdate}
          setVisibility={setUpdateVisibility}
          order={activeItem}
        />
      )}
      {showDeleteConfirm && (
        <Flex
          sx={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            zIndex: 9999999,
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column',
          }}
          bg="background"
        >
          r u sure u wanna delete
          {orders?.find(el => el.id === deleteId)?.brand}?
          <Box>
            <Button py={1} px={3} mx={1} bg="bgRush" onClick={onDelete}>
              Yep
            </Button>
            <Button
              py={1}
              px={3}
              mx={1}
              bg="secondary"
              onClick={() => setDeleteConfirm(false)}
            >
              Nah
            </Button>
          </Box>
        </Flex>
      )}
      <Flex sx={{ ...flexStyle }}>
        {orders &&
          orders.map((order: IOrder, key: any) => {
            return (
              <Orders
                key={key}
                order={order}
                showEdit={showEdit}
                setDeleteConfirm={setDeleteConfirm}
                setDeleteId={setDeleteId}
                onMouseOut={onMouseOut}
                onMouseOver={onMouseOver}
                isHoverId={isHoverId}
              />
            )
          })}
        {nextVisible ? (
          <Button onClick={fetchNext} bg="secondary" py={1}>
            Next
          </Button>
        ) : (
          <Box sx={{ textAlign: 'center' }}>No more na</Box>
        )}
      </Flex>
    </Box>
  )
}
const Orders = (props: any) => {
  const {
    showEdit,
    order,
    setDeleteConfirm,
    setDeleteId,
    onMouseOver,
    onMouseOut,
    isHoverId,
  } = props || {}
  const {
    brand,
    clientName,
    type,
    poc,
    status,
    size,
    quantity,
    totalA4,
    price,
    dueDate,
    layoutLink,
    rush,
    comment,
    height = 0,
    width = 0,
    unit = ''
  } = order || {}
  return (
    <Flex
      mb={2}
      sx={{
        alignItems: 'center',
        flexFlow: ['column', 'column', 'row'],
        position: 'relative',
      }}
      onMouseOver={() => onMouseOver(order.id)}
    >
      {isHoverId === order.id && (
        <Flex
          sx={{
            position: 'absolute',
            justifyContent: 'space-around',
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.8)',
            top: 0,
            alignItems: 'center',
          }}
          onMouseOut={onMouseOut}
        >
          <Button
            bg="bgRush"
            sx={{ height: '44px' }}
            onClick={() => {
              setDeleteConfirm(true)
              setDeleteId(order.id)
            }}
          >
            Delete
          </Button>
          <Button
            bg="#444444"
            sx={{ height: '44px' }}
            onClick={() => showEdit(order.id)}
          >
            Edit
          </Button>
        </Flex>
      )}
      <Flex
        bg="secondaryDark"
        sx={{
          flexFlow: ['row', 'row', 'column'],
          width: '100%',
          maxWidth: ['100%', '100%', '250px'],
          fontWeight: '500',
          flexWrap: 'wrap',
        }}
        p={2}
      >
        <Box sx={{ ...textOverflow }} mx={1}>
          {brand}
        </Box>
        <Box sx={{ ...textOverflow }} mx={1}>
          {clientName}
        </Box>
        <Box mx={1} sx={{ flex: ['1 1 100%', 'auto', 'auto'] }}>
          <Box sx={{ ...badges }}>{totalA4 || 0} sheets</Box>
          <Box sx={{ ...badges }} ml={1}>
            {poc}
          </Box>
        </Box>
      </Flex>
      <Flex sx={{ flex: 1, width: '100%' }}>
        <Flex sx={{ width: '70%' }}>
          <Flex
            p={[1, 1, 2]}
            sx={{
              width: '100%',
              maxWidth: '220px',
              fontSize: ['14px', '18px', '21px'],
              fontWeight: 'bold',
              justifyContent: 'center',
            }}
          >
            <Box>{width && height && unit ? `${width}x${height}${unit}` : size}</Box>
          </Flex>
          <Flex
            p={[1, 1, 2]}
            sx={{
              width: '100%',
              maxWidth: '150px',
              fontSize: ['14px', '18px', '21px'],
              fontWeight: 'bold',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Box>{quantity || 0}</Box>
            <Box sx={{ fontSize: '12px' }}>&nbsp;pcs</Box>
          </Flex>
          <Flex
            p={[1, 1, 2]}
            sx={{
              width: '100%',
              maxWidth: '150px',
              fontSize: ['14px', '18px', '21px'],
              fontWeight: 'bold',
              justifyContent: 'flex-end',
              flexFlow: 'column',
            }}
          >
            <Box>{type === 'TARPAULIN' ? 'TARP' : type}</Box>
          </Flex>
        </Flex>
        <Flex
          sx={{
            flexGrow: [0, 0, 1],
            alignItems: 'center',
            flex: [1, 1, 'auto'],
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '150px',
              fontSize: ['14px', '18px', '21px'],
              fontWeight: 'bold',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {status}
          </Box>

          <Flex
            p={[1, 1, 2]}
            sx={{
              width: '100%',
              maxWidth: '150px',
              fontSize: ['14px', '18px', '21px'],
              fontWeight: 'bold',
              justifyContent: 'flex-end',
            }}
          >
            <Box>${price || 0}</Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const badges = {
  display: 'inline-block',
  borderRadius: '2px',
  fontWeight: '500',
  background: '#3CB280',
  marginBottom: '2px',
  fontSize: '10px',
  lineHeight: 1.2,
  padding: '2px',
}
const flexStyle = {
  flexDirection: 'column',
  position: 'relative',
}
const textOverflow = {
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const gridTable = {
  fontWeight: 500,
  gridTemplateColumns: '200px 300px 100px 10px',

  '> div': {
    padding: '4px 2px',
  },
}
