import React from 'react'
import Item from './item'
import { Droppable } from 'react-beautiful-dnd'
import { jsx, Link, Image, Flex, Text, Grid, Box, Heading } from 'theme-ui'
import { IOrder } from 'types'

interface ColumnProps {
  col: {
    id: string
    list: any[]
  }
}

// const StyledColumn ="div", {
//   padding: "24px 0",
//   display: "flex",
//   flexDirection: "column",
//   marginTop: 8,

//   h2: {
//     margin: 0,
//     padding: "0 16px"
//   }
// };

// const StyledList = styled("div", {
//   backgroundColor: "#ddd",
//   borderRadius: 8,
//   padding: 16,
//   display: "flex",
//   flexDirection: "column",
//   flexGrow: 1,
//   marginTop: 8
// });

const Column: React.FC<ColumnProps> = ({ col: { list, id } }) => {
  return (
    <Droppable droppableId={id}>
      {provided => (
        <Box
          sx={{
            width: '300px',
            margin: '0 2px',
            padding: ['0 2px', '0 10px', '0 10px'],

            '> div': {
              minHeight: '550px',
            },
          }}
        >
          <Heading as="h4" pt={2}>
            {id}
          </Heading>
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {list &&
              list.map((order: IOrder, index) => (
                <Item key={order.id} order={order} index={index} column={id} />
              ))}
            {provided.placeholder}
          </div>
        </Box>
      )}
    </Droppable>
  )
}

export default Column
