/** @jsx jsx */
import React, { useContext, useEffect } from 'react'
import { jsx, Box, Button } from 'theme-ui'

import { AuthContext } from 'context/auth-context'
import { generateDatesFirebase } from 'api'

export const Display: React.FC = () => {
  const authContext = useContext(AuthContext)

  return (
    <Box>
      <Button variant="button.plain" onClick={authContext.logout}>
        Logout
      </Button>
    </Box>
  )
}

export default Display
