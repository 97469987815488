/** @jsx jsx */
import React, { useContext } from 'react'
import { jsx, Link, Image, Flex, Button, NavLink, Box } from 'theme-ui'
import Authentication from 'context/auth-context'
import { AuthContext } from 'context/auth-context'
import HomeIcon from 'assets/home.svg'
import ListIcon from 'assets/list.svg'
import SettingIcon from 'assets/setting.svg'
import CalculatorIcon from 'assets/calculator.svg'
import ChartIcon from 'assets/chart.svg'
import Expenses from 'assets/expenses.svg'
import { TABS } from 'consts'
import Logo from 'assets/logo.svg'

export default function Layout(props: any) {
  const authContext = useContext(AuthContext)
  return (
    <Box
      pt={2}
      sx={{
        marginBottom: '53px',
      }}
    >
      <Box
        sx={{
          height: '200px',
          textAlign: 'center',
          position: 'fixed',
          top: '-200px',
          width: '100%',
          zIndex: '99',
        }}
        bg="background"
      >
        {/* <Logo sx={{ height: '10px', top: '-5px', position: 'relative' }} /> */}
      </Box>
      {props.children}
      <Flex
        as="nav"
        sx={{
          img: {
            height: '20px',
          },
          button: {
            padding: 0,
            background: 'transparent',
            height: '45px',
            width: '45px',
            textAlign: 'center',
          },
          svg: {
            width: '100%',
            height: '100%',
            path: {
              fill: '#f1f1f1',
            },
          },
          justifyContent: 'space-evenly',
          position: 'fixed',
          width: '100%',
          bottom: 0,
          zIndex: 99,
          borderTop: '1px solid rgba(242, 242, 242, 0.2)',
          alignItems: 'center',
          textAlign: 'center',
        }}
        bg="background"
      >
        <Button onClick={() => authContext.setActiveTab(TABS.HOME)}>
          <Flex
            sx={{
              alignItems: 'center',
              width: '20px',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <HomeIcon
              sx={{
                path: {
                  fill:
                    authContext.activeTab === TABS.HOME && '#FDC300 !important',
                },
              }}
            />
          </Flex>
        </Button>
        <Button onClick={() => authContext.setActiveTab(TABS.LIST)}>
          <Flex
            sx={{
              alignItems: 'center',
              width: '20px',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <ListIcon
              sx={{
                path: {
                  fill:
                    authContext.activeTab === TABS.LIST && '#FDC300 !important',
                },
              }}
            />
          </Flex>
        </Button>
        <Button onClick={() => authContext.setActiveTab(TABS.CALCULATOR)}>
          <Flex
            sx={{
              alignItems: 'center',
              width: '20px',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <CalculatorIcon
              sx={{
                path: {
                  fill:
                    authContext.activeTab === TABS.CALCULATOR &&
                    '#FDC300 !important',
                },
              }}
            />
          </Flex>
        </Button>
        <Button onClick={() => authContext.setActiveTab(TABS.SALES)}>
          <Flex
            sx={{
              alignItems: 'center',
              width: '20px',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <ChartIcon
              sx={{
                path: {
                  fill:
                    authContext.activeTab === TABS.SALES &&
                    '#FDC300 !important',
                },
              }}
            />
          </Flex>
        </Button>

        <Button onClick={() => authContext.setActiveTab(TABS.EXPENSES)}>
          <Flex
            sx={{
              alignItems: 'center',
              width: '20px',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <Expenses
              sx={{
                path: {
                  fill:
                    authContext.activeTab === TABS.EXPENSES &&
                    '#FDC300 !important',
                },
              }}
            />
          </Flex>
        </Button>
        <Button onClick={() => authContext.setActiveTab(TABS.SETTING)}>
          <Flex
            sx={{
              alignItems: 'center',
              width: '20px',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <SettingIcon
              sx={{
                path: {
                  fill:
                    authContext.activeTab === TABS.SETTING &&
                    '#FDC300 !important',
                },
              }}
            />
          </Flex>
        </Button>
      </Flex>
    </Box>
  )
}
