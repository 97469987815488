/** @jsx jsx */
import React, { useContext, useEffect } from 'react'
import { jsx, Box } from 'theme-ui'
import List from 'components/admin/list'

import Layout from 'components/admin/layout'
import Workflow from 'components/admin/worflow'
import Setting from 'components/admin/setting'
import Compute from 'components/compute'
import Sales from 'components/admin/sales'
import Expenses from 'components/admin/expenses'
import { AuthContext } from 'context/auth-context'
import { TABS } from 'consts'

export const Display: React.FC = () => {
  const authContext = useContext(AuthContext)

  return (
    <Box
      sx={{
        textRendering: 'optimizeLegibility',
      }}
    >
      {authContext.activeTab == TABS.HOME && <Workflow />}
      {authContext.activeTab == TABS.LIST && <List />}
      {authContext.activeTab == TABS.SETTING && <Setting />}
      {authContext.activeTab == TABS.EXPENSES && <Expenses />}
      {authContext.activeTab == TABS.SALES && <Sales />}
      {authContext.activeTab == TABS.CALCULATOR && (
        <Box p={2} pb={5}>
          <Compute />
        </Box>
      )}
    </Box>
  )
}

export default Display
