import React, { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Image } from 'theme-ui'
import firebase from 'gatsby-plugin-firebase'
import Logo from 'assets/logo.svg'
import { TABS } from 'consts'

export const AuthContext = React.createContext<any>({})

const Authentication: React.FC<{}> = ({ children }) => {
  const [isLogin, setLogin] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [showLoginError, setLoginErrorVisibility] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<string>(TABS.HOME)

  const login = useCallback(async event => {
    event.preventDefault()
    event.stopPropagation()
    setLoading(true)
    const form = event.target.elements
    const email = form.email.value
    const password = form.password.value
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(function () {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(user => {
            // Signed in
            // ...
            setLogin(true)
            setLoginErrorVisibility(false)
          })
          .catch(error => {
            console.log(error)
            setLogin(false)
            setLoading(false)
            setLoginErrorVisibility(true)
            const errorCode = error.code
            const errorMessage = error.message
          })
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        setLoginErrorVisibility(true)
        setLogin(false)
      })
  }, [])
  const logout = useCallback(() => {
    return firebase
      .auth()
      .signOut()
      .then(function () {
        setLogin(false)
        setLoading(false)
        setLoginErrorVisibility(false)
      })
      .catch(function (error) {
        setLoading(false)
        setLogin(false)
        setLoginErrorVisibility(true)
        // An error happened.
      })
  }, [])

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setLogin(true)
      } else {
        setLogin(false)
      }
      setLoading(false)
    })
  }, [])
  return (
    <AuthContext.Provider
      value={{ login, isLogin, logout, activeTab, setActiveTab }}
    >
      {showLoginError && (
        <Box bg="bgRush" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          sumtin went wrong???!?
        </Box>
      )}
      {isLoading ? (
        <Flex
          sx={{
            height: '100vh',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          <Flex
            sx={{
              flexFlow: 'column',
            }}
          >
            <Box sx={{ width: '200px' }}>
              <Logo />
            </Box>
            <Box>Loading...</Box>
          </Flex>
        </Flex>
      ) : (
        children
      )}
    </AuthContext.Provider>
  )
}

export default Authentication
