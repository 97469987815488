import React, { useCallback, useContext, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { IOrder } from 'types'
import { Box, Link, Grid, Button, Flex } from 'theme-ui'
import { STATUS, PRINT_TYPE, DAYS, MONTHS } from '../../consts'
import { WorkFlowContext } from './worflow'
import { timestampToDatetimeInputString } from 'helpers'

interface ItemProps {
  order: IOrder
  index: number
  column: any
}

const Item: React.FC<ItemProps> = ({ order, index, column }) => {
  const [isHover, setIsHover] = useState<boolean>(false)
  const workflowManager = useContext(WorkFlowContext)
  const onMouseOver = useCallback(() => {
    setIsHover(true)
  }, [])
  const onMouseOut = useCallback(() => {
    setIsHover(false)
  }, [])
  const dueDateSec = order.dueDate?.seconds
  let dueDateTime = new Date(dueDateSec * 1000)
  const due = `${MONTHS[dueDateTime.getMonth()]
    .substring(0, 3)
    .toUpperCase()} ${dueDateTime.getDate()}, ${DAYS[dueDateTime.getDay()]}`

  return (
    <Draggable draggableId={order.id} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Box py={1} px={0} m={0} sx={{ position: 'relative' }}>
            <Box
              sx={{
                borderRadius: '5px',
                position: 'relative',
                boxShadow: '2px 2px 2px rgba(68, 68, 68, 0.3)',
                overflow: 'hidden',
              }}
              pl={2}
              pr={1}
              py={1}
              bg={order.rush ? '#B22222' : 'secondaryDark'}
            >
              <Box
                sx={{
                  width: '3px',
                  height: '100%',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                }}
                bg={
                  order.type === PRINT_TYPE.GLOSSY.id
                    ? '#FCE8B2'
                    : order.type === PRINT_TYPE.CLEAR.id
                    ? '#C9DAF8'
                    : '#D9D2E9'
                }
              ></Box>
              <Box sx={{ fontWeight: 'bold', lineHeight: 1.2 }} p={0}>
                {order.brand}
              </Box>
              <Box
                sx={{ fontSize: '14px', fontWeight: '500', lineHeight: 1.2 }}
                mb={1}
              >
                {order.clientName}
              </Box>

              <Grid sx={{ fontSize: '10px' }} columns={[1, 2, 4]} gap={0} m={0}>
                {order.width && order.height && order.unit ? (
                  <Box
                    px={1}
                    sx={{
                      ...badges,
                    }}
                  >
                    {order.width}x{order.height}{order.unit}
                  </Box>
                ) : order.size && (
                  <Box
                    px={1}
                    sx={{
                      ...badges,
                    }}
                  >
                    {order.size}
                  </Box>
                )}
                <Box
                  px={1}
                  sx={{
                    ...badges,
                  }}
                >
                  {order.quantity || 0}pcs
                </Box>
              </Grid>
              <Grid
                sx={{ fontSize: '10px', padding: 0 }}
                gap={0}
                columns={[2, 2, 2]}
              >
                <Box
                  px={1}
                  sx={{
                    ...badges,
                  }}
                >
                  ${order.price || 0}
                </Box>
                <Box
                  px={1}
                  sx={{
                    ...badges,
                  }}
                >
                  {order.poc}
                </Box>

                <Box
                  px={1}
                  sx={{
                    ...badges,
                    cursor: 'pointer',
                  }}
                >
                  <Link
                    href={order.layoutLink}
                    target="_blank"
                    color="text"
                    sx={{ textDecoration: 'none' }}
                  >
                    LINK
                  </Link>
                </Box>
                <Box
                  sx={{
                    ...badges,
                    cursor: 'help',
                    color: '#000',
                    background:
                      order.type === PRINT_TYPE.GLOSSY.id
                        ? '#FCE8B2'
                        : order.type === PRINT_TYPE.CLEAR.id
                        ? '#C9DAF8'
                        : '#D9D2E9',
                  }}
                >
                  {order.type === 'TARPAULIN' ? 'TARP' : order.type}
                </Box>
              </Grid>
              <Grid columns={1} gap={0}>
                {order.comment && (
                  <Box
                    px={1}
                    sx={{
                      ...badges,
                      cursor: 'help',
                    }}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                  >
                    Comment
                  </Box>
                )}
                <Box
                  px={1}
                  sx={{
                    ...badges,
                  }}
                >
                  DUE {due}
                </Box>
              </Grid>

              <Box>
                <Grid
                  mt={3}
                  columns={2}
                  sx={{
                    fontSize: '10px',
                    padding: 0,
                    button: {
                      padding: '2px',
                      borderRadius: '2px',
                    },
                  }}
                  gap={1}
                >
                  <Button
                    bg="#444444"
                    p={0}
                    onClick={() => workflowManager.showEdit(order.id)}
                  >
                    &#9998; Edit
                  </Button>
                  <Button
                    bg={'#3CB280'}
                    p={0}
                    onClick={() => workflowManager.onClickDone(order.id)}
                  >
                    &#10003; Done
                  </Button>
                </Grid>
              </Box>
            </Box>
            {isHover && (
              <Box p={0} sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    width: 0,
                    height: 0,
                    borderLeft: '5px solid transparent',
                    borderRight: '5px solid transparent',
                    borderBottom: '5px solid #f1f1f1',
                    position: 'absolute',
                    top: '4px',
                    left: '4px',
                  }}
                ></Box>
                <Box
                  sx={{
                    position: 'absolute',
                    fontSize: '12px',
                    background: '#231F1F',
                    zIndex: '99',
                    whiteSpace: 'break-spaces',
                    border: '1px solid #f1f1f1',
                    borderRadius: '5px',
                  }}
                  bg="secondaryDark"
                  mt={2}
                  p={2}
                >
                  {order.comment}
                </Box>
              </Box>
            )}
          </Box>
        </div>
      )}
    </Draggable>
  )
}

const badges = {
  display: 'inline-block',
  borderRadius: '2px',
  fontWeight: '500',
  background: '#3CB280',
  fontSize: '10px',
  padding: '2px',
  marginBottom: '2px',
  marginRight: '2px',
}

const floatBtn = {
  borderRadius: '0 0 0 0',
  width: '15px',
  height: '15px',
  position: 'absolute',
  right: '0',
  top: '0',
  cursor: 'pointer',
  fontSize: '8px',
  lineHeight: 0,
  textAlign: 'center',
}

const btnEdit = {
  top: 'auto',
  bottom: '0',
}

export default Item
