import firebase from 'gatsby-plugin-firebase'

export const updateDailySales = async (
  newSale: number = 0,
  orderDate: any,
  isDelete: boolean = false
) => {
  const db = firebase.firestore()

  let orderDateTime = new Date(orderDate.seconds * 1000)
  orderDateTime.setHours(0)
  orderDateTime.setMinutes(0)
  orderDateTime.setSeconds(0)
  orderDateTime.setMilliseconds(0)
  orderDateTime.getTime()

  const orderDateTimestamp = firebase.firestore.Timestamp.fromDate(
    orderDateTime
  )
  const data = await db
    .collection('DailySales')
    .where('date', '==', orderDateTimestamp)
    .limit(1)
    .get()

  const dailySales: any = data.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }))

  if (dailySales.length === 1) {
    const id = dailySales[0].id
    const sales = dailySales[0].sales
    let daily: any = null
    if (isDelete) {
      daily = {
        sales: Number(sales - newSale),
      }
    } else {
      daily = {
        sales: Number(sales + newSale),
      }
    }

    await db.collection('DailySales').doc(id).update(daily)

    await updateMonthlySales(
      {
        value: newSale,
        date: orderDate,
      },
      isDelete
    )
  }
}

export const updateMonthlySales = async (
  sales: any,
  isDelete: boolean = false
) => {
  const db = firebase.firestore()

  const newSales = sales.value
  let dateTime = new Date(sales.date.seconds * 1000)
  dateTime.setHours(0)
  dateTime.setMinutes(0)
  dateTime.setSeconds(0)
  dateTime.setMilliseconds(0)
  dateTime.setDate(1)
  dateTime.getTime()

  const dateTimestamp = firebase.firestore.Timestamp.fromDate(dateTime)

  const data = await db
    .collection('MonthlySales')
    .where('date', '==', dateTimestamp)
    .limit(1)
    .get()

  const monthlySales: any = data.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }))

  if (monthlySales.length === 1) {
    const id = monthlySales[0].id
    const value = monthlySales[0].value
    let monthy: any = null
    if (isDelete) {
      monthy = {
        value: Number(value - newSales),
      }
    } else {
      monthy = {
        value: Number(value + newSales),
      }
    }

    await db.collection('MonthlySales').doc(id).update(monthy)
  }
}

export const getSales = async () => {
  const db = firebase.firestore()
  const dateOffset = 24 * 60 * 60 * 1000 * 10 //5 days
  const startDate = new Date()
  startDate.setTime(startDate.getTime() - dateOffset)

  let start = firebase.firestore.Timestamp.fromDate(new Date(startDate))
  let end = firebase.firestore.Timestamp.fromDate(new Date())

  const data = await db
    .collection('DailySales')
    .where('date', '>=', start)
    .where('date', '<=', end)
    .orderBy('date', 'asc')
    .limit(10)
    .get()

  const dailySales: any = data.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }))

  return dailySales
}

export const generateDatesFirebase = async () => {
  const fromDate = new Date('2021-01-01')
  const toDate = new Date('2021-12-31')
  var daysOfYear = []
  // const firestore = getFirestore();
  const db = firebase.firestore()
  for (var d = fromDate; d <= toDate; d.setDate(d.getDate() + 1)) {
    daysOfYear.push(new Date(d))
    const date = new Date(d)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    const data = { date, sales: 0 }

    // firestore.createDocument("DailySales", data);

    await db.collection('DailySales').add(data)
  }
}

export const generateDatesExpense = async () => {
  const fromDate = new Date('2021-01-01')
  const toDate = new Date('2021-12-31')
  var daysOfYear = []
  // const firestore = getFirestore();
  const db = firebase.firestore()
  for (var d = fromDate; d <= toDate; d.setDate(d.getDate() + 1)) {
    daysOfYear.push(new Date(d))
    const date = new Date(d)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    const data = { date, value: 0 }

    await db.collection('DailyExpenses').add(data)
  }
}

export const getExpenses = async () => {
  const db = firebase.firestore()
  const dateOffset = 24 * 60 * 60 * 1000 * 10 //5 days
  const startDate = new Date()
  startDate.setTime(startDate.getTime() - dateOffset)

  let start = firebase.firestore.Timestamp.fromDate(new Date(startDate))
  let end = firebase.firestore.Timestamp.fromDate(new Date())

  const data = await db
    .collection('DailyExpenses')
    .where('date', '>=', start)
    .where('date', '<=', end)
    .orderBy('date', 'asc')
    .limit(10)
    .get()

  const dailyExpenses: any = data.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }))

  return dailyExpenses
}

export const updateMonthlyExpenses = async (
  expense: any,
  isDelete: boolean = false
) => {
  const db = firebase.firestore()
  const newExpense = expense.value
  let dateTime = new Date(expense.date.seconds * 1000)
  dateTime.setHours(0)
  dateTime.setMinutes(0)
  dateTime.setSeconds(0)
  dateTime.setMilliseconds(0)
  dateTime.setDate(1)
  dateTime.getTime()

  const dateTimestamp = firebase.firestore.Timestamp.fromDate(dateTime)

  const data = await db
    .collection('MonthlyExpenses')
    .where('date', '==', dateTimestamp)
    .limit(1)
    .get()

  const monthlyExpense: any = data.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }))

  if (monthlyExpense.length === 1) {
    const id = monthlyExpense[0].id
    const value = monthlyExpense[0].value
    let monthy: any = null
    if (isDelete) {
      monthy = {
        value: Number(value - newExpense),
      }
    } else {
      monthy = {
        value: Number(value + newExpense),
      }
    }

    await db.collection('MonthlyExpenses').doc(id).update(monthy)
  }
}

export const getMonthlySales = async () => {
  const db = firebase.firestore()
  const startDate = new Date()
  startDate.setDate(1)
  startDate.setMonth(startDate.getMonth() - 12)
  startDate.setTime(startDate.getTime())

  let start = firebase.firestore.Timestamp.fromDate(new Date(startDate))
  let end = firebase.firestore.Timestamp.fromDate(new Date())

  const data = await db
    .collection('MonthlySales')
    .where('date', '>=', start)
    .where('date', '<=', end)
    .orderBy('date', 'asc')
    .limit(14)
    .get()

  const monthlySales: any = data.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }))

  return monthlySales
}

export const getMonthlyExpenses = async () => {
  const db = firebase.firestore()
  const startDate = new Date()
  startDate.setDate(1)
  startDate.setMonth(startDate.getMonth() - 12)
  startDate.setTime(startDate.getTime())

  let start = firebase.firestore.Timestamp.fromDate(new Date(startDate))
  let end = firebase.firestore.Timestamp.fromDate(new Date())

  const data = await db
    .collection('MonthlyExpenses')
    .where('date', '>=', start)
    .where('date', '<=', end)
    .orderBy('date', 'asc')
    .limit(14)
    .get()

  const monthlyExpenses: any = data.docs.map(doc => ({
    ...doc.data(),
    id: doc.id,
  }))

  return monthlyExpenses
}
