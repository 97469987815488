/** @jsx jsx */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  jsx,
  Link,
  Image,
  Flex,
  Text,
  Grid,
  Box,
  Input,
  Textarea,
  Label,
  Select,
  Button,
  Close,
  Checkbox,
} from 'theme-ui'
import GoogleLogin from 'react-google-login'
import { IOrder } from 'types'
import { POC, PRINT_TYPE, STATUS } from '../../consts'
import {
  compute,
  setDefaultDueDate,
  timestampToDatetimeInputString,
} from '../../helpers'

export default function Form(props: {
  order?: IOrder
  onSubmit: Function
  setVisibility: Function
}) {
  const {
    brand,
    clientName,
    type,
    poc,
    status,
    size,
    quantity,
    totalA4,
    price,
    dueDate,
    layoutLink,
    rush,
    comment,
    id,
    width,
    height,
    unit,
  } = props.order || {}

  const onSubmit = useCallback(event => {
    if (props.order) {
      props.onSubmit(event, id)
    } else {
      props.onSubmit(event)
    }
    props.setVisibility(false)
  }, [])

  const docHeight = useRef<HTMLInputElement | any>(null)
  const docWidth = useRef<HTMLInputElement | any>(null)
  const docUnit = useRef<HTMLSelectElement | any>(null)
  const docPcs = useRef<HTMLInputElement | any>(null)
  const docPrice = useRef<HTMLInputElement | any>(null)
  const docSheets = useRef<HTMLInputElement | any>(null)

  const onCompute = (event: any) => {
    event.preventDefault()
    if (docWidth && docHeight && docUnit && docPcs) {
      const width = docWidth.current.value
      const height = docHeight.current.value
      const pcs = docPcs.current.value
      const unit = docUnit.current.value
      const computed = compute(width, height, unit, pcs)
      docPrice.current.value = computed.price
      docSheets.current.value = computed.a4pages
    }
  }

  const dueDateSec = dueDate?.seconds
  let dueDateTime = new Date(dueDateSec * 1000).getTime()
  const dueDateVal = dueDate
    ? timestampToDatetimeInputString(dueDateTime)
    : setDefaultDueDate()

  return (
    <React.Fragment>
      <Box
        sx={{
          position: 'fixed',
          top: '0',
          width: '100%',
          zIndex: '99999',
          height: '100vh',
        }}
      >
        <Flex
          sx={{
            backgroundImage: 'url(/grain.png)',
            textRendering: 'optimizeLegibility',
            background: '#231F1F',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column',
          }}
        >
          <Box
            sx={{
              position: 'fixed',
              top: '5px',
              right: '5px',
              background: '#231F1F',
              zIndex: '1',
              borderRadius: '5px',
              border: '1px solid #f1f1f1',
            }}
          >
            <Close onClick={() => props.setVisibility(false)} />
          </Box>
          <Box
            as="form"
            p={1}
            sx={{ fontSize: '14px', maxWidth: '600px', position: 'relative' }}
            onSubmit={onSubmit}
            pt={3}
          >
            <Label htmlFor="brand" mt={0}>
              Brand Name
            </Label>
            <Input name="brand" mb={2} p={1} required defaultValue={brand} />

            <Label htmlFor="clientName">Client Name</Label>
            <Input
              name="clientName"
              mb={3}
              p={1}
              required
              defaultValue={clientName}
            />

            <Grid
              px={1}
              gap={1}
              columns={[3, 3, 3]}
              sx={{ maxWidth: '620px' }}
              mb={2}
            >
              <Select
                p={1}
                sx={{ maxWidth: '200px' }}
                name="type"
                defaultValue={type}
              >
                {Object.entries(PRINT_TYPE).map(([key, value]) => {
                  return (
                    <option key={key} sx={{ color: '#000' }} value={key}>
                      {value.name}
                    </option>
                  )
                })}
              </Select>
              <Select
                p={1}
                sx={{ maxWidth: '200px' }}
                name="poc"
                defaultValue={poc}
              >
                {Object.entries(POC).map(([key, value]) => {
                  return (
                    <option key={key} sx={{ color: '#000' }} value={key}>
                      {value.name}
                    </option>
                  )
                })}
              </Select>
              <Select
                p={1}
                sx={{ maxWidth: '200px' }}
                name="status"
                defaultValue={status}
              >
                {Object.entries(STATUS).map(([key, value]) => {
                  return (
                    <option key={key} sx={{ color: '#000' }} value={key}>
                      {value.text}
                    </option>
                  )
                })}
              </Select>
            </Grid>
            <Grid columns={[5, 5, 5]} gap={1}>
              <Box>
                <Label>Width</Label>
                <Input
                  name="width"
                  mb={2}
                  p={1}
                  type="number"
                  step=".01"
                  ref={docWidth}
                  defaultValue={width}
                />
              </Box>
              <Box>
                <Label>Height</Label>
                <Input
                  name="height"
                  mb={2}
                  p={1}
                  type="number"
                  step=".01"
                  ref={docHeight}
                  defaultValue={height}
                />
              </Box>
              <Box>
                <Label>Qty</Label>
                <Input
                  name="quantity"
                  mb={2}
                  p={1}
                  type="number"
                  step=".01"
                  defaultValue={quantity}
                  ref={docPcs}
                />
              </Box>
              <Box>
                <Label>Unit</Label>
                <Select
                  p={1}
                  defaultValue={unit}
                  sx={{
                    option: {
                      color: '#231F1F',
                    },
                  }}
                  ref={docUnit}
                  name="unit"
                >
                  <option>in</option>
                  <option>cm</option>
                  <option>mm</option>
                </Select>
              </Box>
              <Box>
                <Button
                  sx={{ width: '100%' }}
                  p={1}
                  mt={24}
                  onClick={onCompute}
                >
                  Fill
                </Button>
              </Box>
            </Grid>
            <Grid columns={[2, 2, 2]}>
              <Box>
                <Label htmlFor="price">Price</Label>
                <Input
                  name="price"
                  mb={2}
                  p={1}
                  type="number"
                  step=".01"
                  defaultValue={price}
                  ref={docPrice}
                />
              </Box>
              <Box>
                <Label htmlFor="totalA4">Total Sheets</Label>
                <Input
                  name="totalA4"
                  mb={2}
                  p={1}
                  type="number"
                  step=".01"
                  defaultValue={totalA4}
                  ref={docSheets}
                />
              </Box>
            </Grid>
            {size && (
              <Box>
                <Label htmlFor="size">Size</Label>
                <Input
                  name="size"
                  mb={2}
                  p={1}
                  defaultValue={size}
                  disabled={true}
                />
              </Box>
            )}
            <Grid>
              <Box>
                <Label htmlFor="dueDate">Due Date</Label>
                <Input
                  name="dueDate"
                  mb={2}
                  p={1}
                  type="datetime-local"
                  defaultValue={dueDateVal}
                />
              </Box>
            </Grid>
            <Label htmlFor="layoutLink">Link</Label>
            <Input name="layoutLink" mb={2} p={1} defaultValue={layoutLink} />
            <Label>
              <Checkbox defaultChecked={rush} name="rush" /> RUSH
            </Label>
            <Label htmlFor="comment">Comments</Label>
            <Textarea
              name="comment"
              mb={2}
              p={1}
              defaultValue={comment}
            ></Textarea>
            <Button sx={{ width: '100%' }}>Save</Button>
          </Box>
        </Flex>
      </Box>
    </React.Fragment>
  )
}
