/** @jsx jsx */
import React, { useContext, useEffect } from 'react'
import { jsx, Box } from 'theme-ui'
import List from 'components/admin/list'

import Layout from 'components/admin/layout'
import Authentication from 'context/auth-context'
import Authenticate from 'components/admin/authenticate'
import Display from 'components/admin/display'
import { Helmet } from 'react-helmet'
import 'styles/global.css'
import 'styles/pwa.css'

export const Admin: React.FC = () => {
  return (
    <Box
      sx={{
        textRendering: 'optimizeLegibility',
      }}
    >
      <Helmet
        meta={[
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
          },
          { name: 'apple-mobile-web-app-capable', content: 'yes' },
          {
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'black-translucent',
          },
        ]}
      />
      <Authentication>
        <Authenticate>
          <Layout>
            <Display />
          </Layout>
        </Authenticate>
      </Authentication>
    </Box>
  )
}

export default Admin
