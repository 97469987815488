import React, { useCallback, useEffect, useState } from 'react'
import Column from 'components/admin/column'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { jsx, Link, Image, Flex, Button, Box } from 'theme-ui'
import { IOrder } from 'types'
import firebase from 'gatsby-plugin-firebase'
import { STATUS } from '../../consts'
import Form from 'components/admin/form'
import Plus from 'assets/plus.svg'
import { timestampToDatetimeInputString } from 'helpers'
import { updateDailySales } from 'api'

export const WorkFlowContext = React.createContext<any>({})

export const Workflow: React.FC = () => {
  const [columns, setColumns] = useState<any>()
  const [orders, setOrders] = useState<IOrder[] | undefined>()
  const [addVisiblity, setAddVisiblity] = useState<boolean>(false)
  const [updateVisibility, setUpdateVisibility] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<IOrder>()

  const onDragEnd = async ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null

    // Set start and end variables
    const start = columns[source.droppableId]
    const end = columns[destination.droppableId]

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      )

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index])

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList,
      }

      // Update the state
      setColumns((state: any) => ({ ...state, [newCol.id]: newCol }))
      return null
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      )

      // Create a new start column
      const newStartCol = {
        id: start.id,
        list: newStartList,
      }

      // Make a new end list array
      const newEndList = end.list

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index])

      // Create a new end column
      const newEndCol = {
        id: end.id,
        list: newEndList,
      }

      // Update the state
      setColumns((state: any) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }))
      const order = columns[destination.droppableId].list[destination.index]
      const db = firebase.firestore()
      const response = await db.collection('Orders').doc(order.id).update({
        status: destination.droppableId,
      })

      return null
    }
  }

  const onAdd = useCallback(
    async event => {
      event.preventDefault()
      event.stopPropagation()
      const form = event.target.elements
      let due = new Date(form.dueDate.value)
      const dueDate = firebase.firestore.Timestamp.fromDate(due)
      let now = new Date()
      const orderDate = firebase.firestore.Timestamp.fromDate(now)

      const order = {
        brand: form.brand.value,
        clientName: form.clientName.value,
        type: form.type.value,
        poc: form.poc.value,
        status: form.status.value,
        price: Number(form.price.value) || 0,
        totalA4: Number(form.totalA4.value),
        dueDate,
        orderDate,
        comment: form.comment.value,
        layoutLink: form.layoutLink.value,
        rush: form.rush.checked,
        quantity: Number(form.quantity.value),
        width: Number(form.width.value),
        height: Number(form.height.value),
        unit: form.unit.value
      } as IOrder

      const db = firebase.firestore()
      await db
        .collection('Orders')
        .add(order)
        .then(docRef => {
          console.warn('Document written with ID: ', docRef.id)
          order.id = docRef.id
          if (!!orders) {
            setOrders([...orders, order])
          } else {
            setOrders([order])
          }
          setAddVisiblity(false)
        })
      await updateDailySales(order.price, orderDate)
    },
    [orders, setOrders]
  )

  const fetchData = useCallback(async () => {
    const db = firebase.firestore()
    const data = await db
      .collection('Orders')
      .where('status', 'in', [
        STATUS.RECEIVED.id,
        STATUS.PICKUP.id,
        STATUS.PRINTING.id,
      ])
      .limit(100)
      .get()

    const test = data.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    })) as IOrder[]
    if (test) {
      setOrders(test)
    }
  }, [])

  const onUpdate = useCallback(
    async (event, id: string) => {
      event.preventDefault()
      event.stopPropagation()
      const form = event.target.elements

      let due = new Date(form.dueDate.value)
      const dueDate = firebase.firestore.Timestamp.fromDate(due)

      const order = {
        brand: form.brand.value,
        clientName: form.clientName.value,
        type: form.type.value,
        poc: form.poc.value,
        status: form.status.value,
        price: Number(form.price.value) || 0,
        totalA4: Number(form.totalA4.value),
        dueDate,
        comment: form.comment.value,
        layoutLink: form.layoutLink.value,
        rush: form.rush.checked,
        quantity: Number(form.quantity.value),
        width: Number(form.width.value),
        height: Number(form.height.value),
        unit: form.unit.value
      } as IOrder

      if (orders) {
        const index: number = orders.findIndex(order => order.id === id)
        order.id = id
        order.orderDate = orders[index].orderDate

        if (index > -1) {
          if (orders[index].price !== order.price) {
            const newPrice = order.price
            const oldPrice = orders[index].price
            let price = 0
            price = newPrice - oldPrice
            updateDailySales(price, orders[index].orderDate)
          }
          const newOrders = [...orders]
          newOrders[index] = order

          setOrders(newOrders)
          const db = firebase.firestore()
          await db
            .collection('Orders')
            .doc(id)
            .update(order)
            .then(function () {
              console.warn('Document successfully updated!')
            })
            .catch(function (error) {
              // The document probably doesn't exist.
              console.error('Error updating document: ', error)
            })
        }
      }
    },
    [orders, setOrders]
  )

  const onClickDone = useCallback(
    async (id: string) => {
      if (orders) {
        const index: number = orders.findIndex(order => order.id === id)

        if (index > -1) {
          const newOrders = [...orders]
          newOrders[index].status = STATUS.DONE.id

          setOrders(newOrders)
          const db = firebase.firestore()
          await db
            .collection('Orders')
            .doc(id)
            .update({ status: STATUS.DONE.id })
            .then(function () {
              console.warn('Document successfully updated!')
            })
            .catch(function (error) {
              // The document probably doesn't exist.
              console.error('Error updating document: ', error)
            })
        }
      }
    },
    [orders]
  )

  useEffect(() => {
    const COLS = {
      RECEIVED: {
        id: 'RECEIVED',
        list: [],
      },
      PRINTING: {
        id: 'PRINTING',
        list: [],
      },
      PICKUP: {
        id: 'PICKUP',
        list: [],
      },
    } as any

    if (orders) {
      orders.forEach((item: IOrder) => {
        if (item.status === 'RECEIVED') {
          COLS.RECEIVED.list.push(item)
        }
        if (item.status === 'PRINTING') {
          COLS.PRINTING.list.push(item)
        }
        if (item.status === 'PICKUP') {
          COLS.PICKUP.list.push(item)
        }
      })
      setColumns(COLS)
    }
  }, [orders])

  const showEdit = useCallback(
    (id: string) => {
      if (orders) {
        const item = orders.find(order => order.id === id)
        setActiveItem(item)
        setUpdateVisibility(true)
      }
    },
    [orders]
  )

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <WorkFlowContext.Provider
      value={{ setUpdateVisibility, showEdit, onClickDone }}
    >
      <Box
        sx={{
          textAlign: 'right',
          position: 'fixed',
          bottom: '50px',
          right: '10px',
          fontSize: '40px',
          zIndex: 1,
        }}
        pt={0}
      >
        <Button
          onClick={() => setAddVisiblity(true)}
          px={0}
          py={0}
          sx={{
            svg: {
              width: '80%',
              height: '80%',
            },
            borderRadius: '100%',
            height: '50px',
            width: '50px',
            lineHeight: 0.9,
            padding: '12px',
          }}
          bg="secondary"
        >
          <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Plus />
          </Flex>
        </Button>
      </Box>
      <Box mt={2}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Flex sx={{ justifyContent: 'center' }}>
            {columns &&
              Object.values(columns).map((col: any) => (
                <Column col={col} key={col.id} />
              ))}
          </Flex>
        </DragDropContext>
        {addVisiblity && (
          <Form onSubmit={onAdd} setVisibility={setAddVisiblity} />
        )}
        {updateVisibility && (
          <Form
            onSubmit={onUpdate}
            setVisibility={setUpdateVisibility}
            order={activeItem}
          />
        )}
      </Box>
    </WorkFlowContext.Provider>
  )
}
const initialColumns = {
  RECEIVED: {
    id: 'RECEIVED',
    list: ['item 1', 'item 2'],
  },
  PRINTING: {
    id: 'PRINTING',
    list: [],
  },
  PICKUP: {
    id: 'PICKUP',
    list: [],
  },
}

export default Workflow
